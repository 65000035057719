import config from '../config';
import Store from './Store';
import fetch from '../util/Fetch';

export default class extends Store {
  getBaseUrl() {
    return `${config.apiHost}/api/v1/link/${this.$.packageDetail.slug}`;
  }

  requestAccess(contributor) {
    return fetch(`${this.getBaseUrl()}/contributors`, {
      method: 'POST',
      json: contributor,
    });
  }

  inviteClient(client) {
    return fetch(
      `${this.getBaseUrl()}/contributors/${
        this.$.packageActiveContributor.uuid
      }/clients`,
      {
        method: 'POST',
        json: client,
      }
    );
  }
}
