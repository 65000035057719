import fetch from '../util/Fetch';
import Store from './Store';
import {
  BROADCAST_KEY,
  PACKAGE_OFFERS_VIEW_PREFERENCES,
} from '../util/Constants';

export default class extends Store {
  signup({ name, email, password }) {
    return fetch(`/auth/signup`, {
      method: 'POST',
      json: { name, email, password },
      credentials: 'same-origin',
    });
  }

  login({ email, password }) {
    return fetch(`/auth/login`, {
      method: 'POST',
      json: { email, password },
      credentials: 'same-origin',
    });
  }

  reset({ email }) {
    return fetch(`/auth/reset`, {
      method: 'POST',
      json: {
        email,
      },
    });
  }

  logout() {
    this.$.authToken = '';
    this.$.user = this.$.currentUser = null;
    const promise = fetch(`/auth/logout`, {
      method: 'POST',
      credentials: 'same-origin',
    });
    promise.then(() => {
      try {
        window?.localStorage?.setItem(
          BROADCAST_KEY,
          JSON.stringify({
            email: '',
            provider: '',
            packageUuid: '',
          })
        );

        window?.localStorage?.removeItem(BROADCAST_KEY);
        window?.localStorage?.removeItem(PACKAGE_OFFERS_VIEW_PREFERENCES);
      } catch (e) {
        console.error(e);
      }
    });
    return promise;
  }
}
