import config from '../config';
import { startsWith, set, get } from 'lodash';
import { cloneDeep } from './Shims';

const s3 = config.s3 || {};
const imagePrefix = `${s3.bucketHost}/${s3.bucketName}`;
const s3Host = 'https://s3-us-west-2.amazonaws.com';

export function resize(url, dimensions) {
  if (startsWith(url, s3Host)) {
    url = url.replace(s3Host, s3.bucketHost);
  }

  if (startsWith(url, imagePrefix)) {
    const path = url.substring(imagePrefix.length + 1);
    return `${s3.imageResizer}/fit-in/${dimensions || '320x320'}/${path}`;
  } else {
    return url;
  }
}

export function normalizeCoversheetImages(coversheet) {
  const newCoversheet = cloneDeep(coversheet);
  const coverphotoSize = '1280x1024';
  const companyPhotoSize = '600x600';
  const agentPhotoSize = '480x480';
  set(
    newCoversheet,
    'coverphoto.coverphotoUri',
    resize(get(newCoversheet, 'coverphoto.coverphotoUri'), coverphotoSize)
  );
  set(
    newCoversheet,
    'company.companyImageUri',
    resize(get(newCoversheet, 'company.companyImageUri'), companyPhotoSize)
  );

  const agent1Image = get(newCoversheet, 'agent1.profileImageUri');
  const agent2Image = get(newCoversheet, 'agent2.profileImageUri');

  agent1Image &&
    set(
      newCoversheet,
      'agent1.profileImageUri',
      resize(agent1Image, agentPhotoSize)
    );
  agent2Image &&
    set(
      newCoversheet,
      'agent2.profileImageUri',
      resize(agent2Image, agentPhotoSize)
    );

  return newCoversheet;
}
