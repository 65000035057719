import { computed } from 'mobx';
import fetch from '../util/Fetch';
import config from '../config';
import { sortBy } from 'lodash';
import Store from './Store';

export default class extends Store {
  setSearch(search) {
    this.$.contactListSearch = search;
    this.$.Pagination.update(this.$.contactListPaginator, this.filtered);
  }

  setSort(sort) {
    this.$.contactListSort = sort;
  }

  @computed
  get sorted() {
    return sortBy(this.$.contactList, (c) => {
      switch (this.$.contactListSort) {
        case 'name':
        case 'email':
          return c[this.$.contactListSort].toLowerCase();
        default:
          return c.name.toLowerCase();
      }
    });
  }

  @computed
  get filtered() {
    return this.sorted.filter((c) => {
      const target = `${c.name}  ${c.email} ${c.labels.join(
        ' '
      )}`.toLowerCase();
      const query = this.$.contactListSearch.toLowerCase();
      return query.length === 0 || target.indexOf(query) >= 0;
    });
  }

  @computed
  get paged() {
    return this.$.Pagination.getItems(
      this.$.contactListPaginator,
      this.filtered
    );
  }

  select(contacts, action = 'create') {
    this.$.contactListSelected = contacts;
    this.$.contactListAction = action;
  }

  deselect() {
    this.$.contactListSelected = [];
    this.$.contactListAction = '';
  }

  upsert(contact) {
    const index = this.$.contactList.findIndex((c) => c.uuid === contact.uuid);
    if (index > -1) {
      this.$.contactList[index] = contact;
    } else {
      this.$.contactList.push(contact);
    }
    this.$.Pagination.update(this.$.contactListPaginator, this.filtered);
  }

  remove(contact) {
    this.$.contactList = this.$.contactList.filter(
      (c) => c.uuid !== contact.uuid
    );
    this.$.Pagination.update(this.$.contactListPaginator, this.filtered);
  }

  create(contact) {
    contact.labels = [contact.role || 'Agent'];
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/contacts`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: contact,
      }
    );
  }

  update(contact) {
    contact.labels = [contact.role || 'Agent'];
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/contacts/${contact.uuid}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: contact,
      }
    );
  }

  destroy(contact) {
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/contacts/${contact.uuid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }
}
