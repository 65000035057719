/* eslint-disable class-methods-use-this */
import fetch from '../util/Fetch';
import config from '../config';
import Store from './Store';

export default class extends Store {
  update(body) {
    if (!body) {
      return null;
    }

    // update user
    if (this.$.currentUser.uuid === body.uuid) {
      this.$.currentUser = body;
    }

    // also update the real user
    if (this.$.user.uuid === body.uuid) {
      this.$.user = this.$.currentUser;
    }

    // update the billing object
    this.$.billing = this.$.currentUser.billing;

    return this.$.currentUser;
  }

  async wrapper(promise) {
    let rsp = await promise;
    let json = await rsp.json();

    if (rsp.ok) {
      return this.update(json);
    }
    return { error: true, message: json.message };
  }

  //
  // Profile
  //
  saveProfile(profile) {
    return this.wrapper(
      fetch(
        `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/profile`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: profile,
        }
      )
    );
  }

  saveProfileImg(version) {
    return this.wrapper(
      fetch(
        `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/profile/image`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: version,
        }
      )
    );
  }

  getProfileImgUrl(version) {
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/profile/image/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      }
    );
  }

  saveCompany(profile) {
    return this.wrapper(
      fetch(
        `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/company`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: profile,
        }
      )
    );
  }

  saveCompanyImg(version) {
    return this.wrapper(
      fetch(
        `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/company/image`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: version,
        }
      )
    );
  }

  getCompanyImgUrl(version) {
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/company/image/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      }
    );
  }

  setNotificationPreference(notification) {
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/notifications`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: notification,
      }
    );
  }

  updateSettings(settings) {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/me/settings`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: settings,
      })
    );
  }

  updatePassword(body) {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/me/password`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: body,
      })
    );
  }

  revokeSession(session) {
    return fetch(`${config.apiHost}/api/v1/me/sessions/${session.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  clearDocusignSession() {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/me/signing`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  confirmAccount() {
    return fetch(`${config.apiHost}/api/v1/me/confirm`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  requestAccess({ name, email }) {
    return fetch(`${config.apiHost}/api/v1/me/users/access`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: { email, name },
    });
  }

  // Fetch User's contacts
  getContacts() {
    return fetch(`${config.apiHost}/api/v1/me/contacts`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  /*
    Create/Delete User message templates
    Arguments:
        Valid template types: ['notify', 'broadcast', 'invite']
        Templates:
              {
                  templateNameOne: {
                    subject
                    body
                  },
                  templateNameTwo: null
              }
   Send the new template you want to update / add, if you need to delete a template then just set the template name to null
  */
  updateMessageTemplates = ({ subTemplate, body }) =>
    fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/templates/messages/${subTemplate}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: body,
      }
    );

  updateCoversheetTemplates = ({ subTemplate, body }) =>
    fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/templates/coversheet/${subTemplate}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: body,
      }
    );

  updateEscrowTemplates = ({ body }) =>
    fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/templates/escrow`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: body,
      }
    );
}
