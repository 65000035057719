import Store from './Store';

export default class extends Store {
  isOpen() {
    return this.$.offCanvasOpen;
  }

  toggle() {
    this.$.offCanvasOpen = !this.$.offCanvasOpen;
  }

  dismiss() {
    this.$.offCanvasOpen = false;
  }
}
