import config from '../config';

export default function RetryQueue(fn, duration) {
  if (!duration) {
    duration = config.env === 'mock' ? 1 : 1500;
  }

  let loopTimeout = null;
  return new Promise((resolve, reject) => {
    function loop() {
      fn()
        .then((rsp) => {
          if (rsp.ok) {
            return rsp.json();
          }
          throw new Error('Failed to receive response');
        })
        .then((body) => {
          if (body.status === 'Success') {
            resolve(body.result);
          } else if (!body.status || body.status === 'Failed') {
            reject({ errorType: 'FAILED', errorMessage: 'Task timed out!' });
          } else {
            loopTimeout = setTimeout(loop, duration);
          }
        })
        .catch((err) => {
          if (loopTimeout) {
            clearTimeout(loopTimeout);
          }
          reject(err || { errorType: 'FAILED', errorMessage: 'Job failed!' });
        });
    }
    loop();
  });
}
