import Store from './Store';
import fetch from '../util/Fetch';
import moment from 'moment';
import { sortBy, includes, keyBy } from 'lodash';
import { computed } from 'mobx';

export default class extends Store {
  save(body) {
    if (!body) {
      return null;
    }

    // update user
    this.$.packageActiveContributor = body;
    return this.$.packageActiveContributor;
  }

  async wrapper(promise) {
    let rsp = await promise;
    let json = await rsp.json();

    if (rsp.ok) {
      this.save(json);
      return { error: false, message: '' };
    }
    return { error: true, message: json.message };
  }

  @computed
  get attention() {
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return (
          !c.deleted &&
          (c.pending || c.inactive || c.failed) &&
          this.isVisibleContributor(c)
        );
      }),
      (c) => {
        return c.updatedAt
          ? moment(c.updatedAt).unix()
          : moment(c.updatedAt).unix();
      }
    ).reverse();
  }

  @computed
  get pending() {
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return !c.deleted && c.pending && this.isVisibleContributor(c);
      }),
      (c) => {
        return c.pendingAt
          ? moment(c.pendingAt).unix()
          : moment(c.pendingAt).unix();
      }
    ).reverse();
  }

  @computed
  get failed() {
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return !c.deleted && c.failed && this.isVisibleContributor(c);
      }),
      (c) => {
        return c.updatedAt
          ? moment(c.updatedAt).unix()
          : moment(c.updatedAt).unix();
      }
    ).reverse();
  }

  @computed
  get inactive() {
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return (
          !c.deleted && c.inactive && !c.pending && this.isVisibleContributor(c)
        );
      }),
      (c) => {
        return c.updatedAt
          ? moment(c.updatedAt).unix()
          : moment(c.updatedAt).unix();
      }
    ).reverse();
  }

  @computed
  get active() {
    const offer = this.$.packageOffers.find(
      (offer) => offer.uuid === this.$.packageDetail.acceptedOfferUuid
    );
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return (
          !c.deleted &&
          !c.pending &&
          !c.failed &&
          !c.inactive &&
          this.isActiveContributor(offer, c)
        );
      }),
      (c) => {
        return c.lastViewed
          ? moment(c.lastViewed).unix()
          : moment(c.updatedAt).unix();
      }
    ).reverse();
  }

  @computed
  get archived() {
    const offer = this.$.packageOffers.find(
      (offer) => offer.uuid === this.$.packageDetail.acceptedOfferUuid
    );
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return (
          !c.deleted &&
          !c.pending &&
          !c.failed &&
          !c.inactive &&
          !this.isActiveContributor(offer, c)
        );
      }),
      (c) => {
        return c.lastViewed
          ? moment(c.lastViewed).unix()
          : moment(c.updatedAt).unix();
      }
    ).reverse();
  }

  @computed
  get deleted() {
    return sortBy(
      this.$.packageContributors.filter((c) => {
        return c.deleted;
      }),
      (c) => {
        return c.lastViewed
          ? moment(c.lastViewed).unix()
          : moment(c.updatedAt).unix();
      }
    ).reverse();
  }

  isActiveContributor(offer, contributor) {
    return (
      !offer ||
      includes(['Admin', 'Seller'], contributor.role) ||
      contributor.cliqueUuid === offer.leadContributor.cliqueUuid
    );
  }

  isVisibleContributor(contributor) {
    return (
      this.$.packageActiveContributor.role === 'Admin' ||
      (this.$.packageActiveContributor.role === 'Buyer Agent' &&
        includes(['Buyer Agent', 'Buyer'], contributor.role))
    );
  }

  formatRole(role) {
    if (
      role === this.$.packageActiveContributor.role &&
      includes(['Admin', 'Buyer Agent'], role)
    ) {
      return 'Team Member';
    }
    if (role === 'Admin') {
      return 'Listing Team';
    }
    return role;
  }

  select(contributors) {
    const selectedContributors = keyBy(contributors, (c) => c.uuid);
    this.$.packageContributorsSelected = this.$.packageContributors.filter(
      (c) => selectedContributors[c.uuid]
    );
  }

  deselect() {
    this.$.packageContributorsSelected = [];
  }

  upsert(contributor) {
    const index = this.$.packageContributors.findIndex(
      (c) => c.uuid === contributor.uuid
    );
    if (index > -1) {
      this.$.packageContributors[index] = contributor;
    } else {
      this.$.packageContributors.push(contributor);
    }
    if (this.$.packageActiveContributor.uuid === contributor.uuid) {
      this.$.packageActiveContributor = contributor;
    }
  }

  remove(contributor) {
    this.$.packageContributors = this.$.packageContributors.filter(
      (oldContributor) => contributor.uuid !== oldContributor.uuid
    );
    if (this.$.packageActiveContributor.uuid === contributor.uuid) {
      this.$.packageActiveContributor = null;
    }
  }

  createContributor(contributor) {
    return fetch(`${this.$.Package.getBaseUrl()}/contributors`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: contributor,
    });
  }

  updateContributor(contributor, resendInvitation = false) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/contributors/${contributor.uuid}${
        resendInvitation ? '?resend_invitation=true' : ''
      }`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: contributor,
      }
    );
  }

  remindContributor(contributor) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/contributors/${
        contributor.uuid
      }?resend_invitation=true`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  removeContributor(contributorUuid) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/contributors/${contributorUuid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  grantContributor(contributorUuid) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/contributors/${contributorUuid}/grant`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  // Attachments
  requestAttachment(
    contributorUuid,
    name,
    email,
    phone_number,
    attachment_request_type
  ) {
    return this.wrapper(
      fetch(
        `${this.$.Package.getBaseUrl()}/contributors/${contributorUuid}/attachments/${attachment_request_type}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: {
            inquirer: { name, email, phone_number },
          },
        }
      )
    );
  }

  dismissAttachment(contributorUuid, attachment_request_type) {
    return this.wrapper(
      fetch(
        `${this.$.Package.getBaseUrl()}/contributors/${contributorUuid}/attachments/${attachment_request_type}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
        }
      )
    );
  }
}
