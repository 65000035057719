import Store from './Store';
import { extendObservable } from 'mobx';
import { extend } from 'lodash';

export default class extends Store {
  init({
    key,
    items,
    pageSize = 10,
    shortenAt = 10,
    visiblePages = 3,
    marginPages = 1,
  }) {
    const values = {
      key,
      pageSize,
      numPages: Math.ceil(items.length / pageSize),
      currentPage: 0,
      shortenAt,
      visiblePages,
      marginPages,
    };
    if (!this.$.paginationState[key]) {
      this.$.paginationState[key] = extendObservable({}, values);
    } else {
      extend(this.$.paginationState[key], values);
    }
  }

  update(key, items) {
    if (!this.$.paginationState[key]) {
      this.init({ key, items });
    }
    this.$.paginationState[key].numPages = Math.ceil(
      items.length / this.$.paginationState[key].pageSize
    );
    if (!this.$.paginationState[key].numPages) {
      this.$.paginationState[key].currentPage = 0;
    } else if (
      this.$.paginationState[key].currentPage >=
      this.$.paginationState[key].numPages
    ) {
      this.$.paginationState[key].currentPage =
        this.$.paginationState[key].numPages - 1;
    }
  }

  destroy(key) {
    delete this.$.paginationState[key];
  }

  isPaginated(key) {
    return (
      this.$.paginationState[key] && this.$.paginationState[key].numPages > 1
    );
  }

  getItems(key, items) {
    if (!this.$.paginationState[key]) {
      return [];
    }

    const { pageSize, currentPage } = this.$.paginationState[key];
    return items.filter((item, index) => {
      return (
        index >= pageSize * currentPage && index < pageSize * (currentPage + 1)
      );
    });
  }

  getPages(key) {
    if (!this.$.paginationState[key]) {
      return [];
    }

    const pages = [];
    const { shortenAt, visiblePages, numPages, marginPages, currentPage } =
      this.$.paginationState[key];

    if (numPages <= shortenAt) {
      for (let index = 0; index < numPages; index++) {
        pages.push({
          index,
          label: index + 1,
          active: currentPage === index,
          disabled: false,
        });
      }
    } else {
      let leftSide = visiblePages / 2;
      let rightSide = visiblePages - leftSide;
      if (currentPage > numPages - visiblePages / 2) {
        rightSide = numPages - currentPage;
        leftSide = visiblePages - rightSide;
      } else if (currentPage < visiblePages / 2) {
        leftSide = currentPage;
        rightSide = visiblePages - leftSide;
      }

      for (let index = 0; index < numPages; ++index) {
        if (
          index < marginPages ||
          index >= numPages - marginPages ||
          (index >= currentPage - leftSide && index < currentPage + rightSide)
        ) {
          pages.push({
            index,
            label: index + 1,
            active: currentPage === index,
            disabled: false,
          });
          continue;
        }

        if (pages.length > 0 && pages[pages.length - 1].label !== '...') {
          pages.push({
            index,
            label: '...',
            active: false,
            disabled: true,
          });
        }
      }
    }

    return pages;
  }

  hasNext(key) {
    return (
      this.$.paginationState[key] &&
      this.$.paginationState[key].currentPage <
        this.$.paginationState[key].numPages - 1
    );
  }

  next(key) {
    if (this.hasNext(key)) {
      this.$.paginationState[key].currentPage =
        this.$.paginationState[key].currentPage + 1;
    }
  }

  page(key, currentPage) {
    if (!this.$.paginationState[key]) {
      return;
    }

    if (currentPage < 0) {
      currentPage = 0;
    }
    if (currentPage >= this.$.paginationState[key].numPages) {
      currentPage = this.$.paginationState[key].numPages - 1;
    }
    this.$.paginationState[key].currentPage = currentPage;
  }

  hasPrev(key) {
    return (
      this.$.paginationState[key] && this.$.paginationState[key].currentPage > 0
    );
  }

  prev(key) {
    if (this.hasPrev(key)) {
      this.$.paginationState[key].currentPage =
        this.$.paginationState[key].currentPage - 1;
    }
  }
}
