/* eslint-disable class-methods-use-this */
import moment from 'moment';
import { computed } from 'mobx';
import { sortBy } from 'lodash';

import Store from './Store';
import config from '../config';

import { DOCUMENT_TYPES } from '../util/Constants';
import { normalizeCoversheetImages } from '../util/ImageResizer';
import { extensionLess } from '../util/File';
import fetch from '../util/Fetch';

export default class extends Store {
  save(body) {
    if (!body) {
      return null;
    }

    // update user
    this.$.packageDetail = body;
    return this.$.packageDetail;
  }

  @computed
  get computedAgents() {
    const agents = this.$.packageContributors.filter(
      (contributor) =>
        contributor.email !== this.$.packageActiveContributor.email
    );

    this.$.packageOffers.forEach((offer) => {
      const contributors = this.$.packageContributors.filter((contributor) =>
        offer.leadContributor.role === 'Admin'
          ? contributor.email === offer.agentEmail
          : contributor.cliqueUuid === offer.leadContributor.cliqueUuid
      );
      contributors.length
        ? contributors.forEach((contributor) => (contributor.hasOffer = true))
        : agents.push({
            uuid: offer.uuid,
            name: offer.agentName,
            email: offer.agentEmail,
            role: 'Buyer Agent',
            profileImageUri: offer.agentProfileImageUri,
            hasOffer: true,
          });
    });

    return sortBy(agents, 'name', 'email');
  }

  async wrapper(promise) {
    let rsp = await promise;
    let json = await rsp.json();

    if (rsp.ok) {
      this.save(json);
      return { error: false, message: '' };
    }
    return { error: true, message: json.message };
  }

  getBaseUrl() {
    return `${config.apiHost}/api/v1${
      this.$.currentUser.uuid ? `/users/${this.$.currentUser.uuid}` : ''
    }/packages/${this.$.packageDetail.uuid}`;
  }

  canEdit() {
    return (
      this.isOpen() &&
      (this.$.packageActiveContributor.role === 'Admin' ||
        (this.$.packageDetail.createdFor === 'Buyer' &&
          this.$.packageActiveContributor.role === 'Buyer Agent'))
    );
  }

  canDelete() {
    return (
      this.$.packageActiveContributor.role === 'Admin' ||
      (this.$.packageActiveContributor.role === 'Buyer Agent' &&
        this.$.packageDetail.createdFor === 'Buyer')
    );
  }

  canShare() {
    return (
      !this.isClosed() &&
      (this.$.packageActiveContributor.role === 'Admin' ||
        this.$.packageActiveContributor.role === 'Buyer Agent')
    );
  }

  canMessage() {
    return !this.isClosed() && this.$.packageActiveContributor.role === 'Admin';
  }

  canOffer() {
    return (
      this.$.packageActiveContributor.role === 'Buyer Agent' &&
      this.$.packageDetail.createdFor === 'Seller' &&
      this.$.packageDetail.settings.enableOffers
    );
  }

  canSubmitOffer() {
    return (
      this.$.packageActiveContributor.role === 'Buyer Agent' &&
      (this.$.packageActiveOffer.status === 'SCHEDULED' ||
        this.$.packageActiveOffer.status === 'CREATED')
    );
  }

  canRescindOffer() {
    return (
      this.$.packageActiveContributor.role === 'Buyer Agent' &&
      (this.$.packageActiveOffer.status === 'SUBMITTED' ||
        this.$.packageActiveOffer.status === 'ACKNOWLEDGED')
    );
  }

  canRestoreOffer() {
    return (
      this.$.packageActiveContributor.role === 'Buyer Agent' &&
      this.$.packageActiveOffer.status === 'RESCINDED'
    );
  }

  isOpen() {
    return this.$.packageDetail.property_status === 'Open';
  }

  isInContract() {
    return this.$.packageDetail.property_status === 'In-Contract';
  }

  isClosed() {
    return this.$.packageDetail.property_status === 'Closed';
  }

  isNew(pkg) {
    return (
      !pkg.activeContributor.lastViewed &&
      moment(pkg.activeContributor.createdAt) >
        moment().subtract(1, 'months') &&
      pkg.property_status === 'Open'
    );
  }

  create(pkg) {
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/packages`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: pkg,
      }
    );
  }

  update(pkg) {
    return this.wrapper(
      fetch(
        `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/packages/${pkg.uuid}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: pkg,
        }
      )
    );
  }

  track(type) {
    return fetch(`${this.getBaseUrl()}/track`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: { type },
    });
  }

  trackDownload() {
    return fetch(`${this.getBaseUrl()}/download`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  close() {
    return this.wrapper(
      fetch(`${this.getBaseUrl()}/close`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  open() {
    return this.wrapper(
      fetch(`${this.getBaseUrl()}/open`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  destroy() {
    return fetch(
      `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}/packages/${this.$.packageDetail.uuid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  updateSettings(settings) {
    return this.wrapper(
      fetch(`${this.getBaseUrl()}/settings`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: settings,
      })
    );
  }

  link(association, mlsId, requestStatus) {
    return this.wrapper(
      fetch(`${this.getBaseUrl()}/link`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          property_mls_association: association,
          property_mls_id: mlsId,
          requestStatus,
        },
      })
    );
  }

  unlink() {
    return this.wrapper(
      fetch(`${this.getBaseUrl()}/unlink`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  updateCoversheet(skipNotification) {
    if (config.env === 'mock') {
      return;
    }
    if (this.$.packageActiveOffer) {
      return;
    }
    const pkg = this.$.packageDetail;
    const documents = this.$.packageDocuments;
    const args = {
      coversheet: normalizeCoversheetImages(pkg.coversheet),
      documents: documents
        .filter((d) => d.type === DOCUMENT_TYPES.DISCLOSURE)
        .map((d) => {
          return {
            uuid: d.uuid,
            name: extensionLess(d.name) || ' ', // Don't allow a falsey name
            pages: d.latest.pages.length,
          };
        }),
    };

    this.$.packageCoversheetSaving = true;
    return fetch(`${config.documentService}/packages/${pkg.uuid}/coversheet2`, {
      method: 'POST',
      json: args,
    })
      .then((rsp) => {
        if (!rsp.ok) {
          throw new Error('Failed to render coversheet!');
        }
        return rsp.json();
      })
      .then((rsp) => {
        return fetch(
          `${config.apiHost}/api/v1${
            this.$.currentUser.uuid ? '/users/' + this.$.currentUser.uuid : ''
          }/packages/${pkg.uuid}/coversheet${
            skipNotification ? '?skip_notification=true' : ''
          }`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${this.$.authToken}`,
            },
            json: rsp.coversheet || rsp,
          }
        );
      })
      .then((rsp) => {
        if (!rsp.ok) {
          throw new Error('Failed to save coversheet!');
        }
        return rsp.json();
      })
      .then((coversheet) => {
        this.$.packageCoversheetSaving = false;
        if (!skipNotification) {
          this.$.PackageDocument.pristine();
        }
        const coversheetIndex = this.$.packageDocuments.findIndex(
          (d) => d.type === DOCUMENT_TYPES.COVERSHEET
        );
        if (coversheetIndex > -1) {
          this.$.packageDocuments[coversheetIndex] = coversheet;
          this.$.PackageDocument.upsert(coversheet);
        }
      })
      .catch((err) => {
        this.$.packageCoversheetSaving = false;
        return err;
      });
  }

  notifyViewers() {
    return fetch(
      `${config.apiHost}/api/v1${
        this.$.currentUser.uuid ? '/users/' + this.$.currentUser.uuid : ''
      }/packages/${this.$.packageDetail.uuid}/coversheet/notify`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  getCoverphotoUrl(version) {
    return fetch(`${this.getBaseUrl()}/coverphoto/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: version,
    });
  }

  getCoversheetImageUrl(type, version) {
    return fetch(`${this.getBaseUrl()}/coversheet/${type}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: version,
    });
  }

  getVideoUrl(version) {
    return fetch(`${this.getBaseUrl()}/videos/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: version,
    });
  }

  uploadPackageVideoToS3(request) {
    return fetch(
      `${config.videoService}/packages/${this.$.packageDetail.uuid}/upload`,
      {
        method: 'POST',
        json: request,
      }
    );
  }

  toggleRequestStatus(status) {
    return this.wrapper(
      fetch(`${this.getBaseUrl()}/requestStatus`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          requestStatus: status,
        },
      })
    );
  }

  orderReport(request) {
    return fetch(`${this.getBaseUrl()}/reports`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: request,
    });
  }

  orderReportByType({ body, type, vendor }) {
    return fetch(`${this.getBaseUrl()}/reports/${vendor}/${type}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: body,
    });
  }

  createEnvelope(
    { accountId, importSigned, emailSubject, emailBlurb, recipients },
    offer
  ) {
    const targetUrl = offer ? `/offers/${offer.uuid}` : '';

    return fetch(
      `${this.getBaseUrl()}${targetUrl}/envelopes/${accountId}${
        importSigned ? '?import=true' : ''
      }`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          emailSubject,
          emailBlurb,
          recipients,
        },
      }
    );
  }

  createEnvelopeSenderView(accountId, envelopeId, returnUrl) {
    return fetch(
      `${this.getBaseUrl()}/envelopes/${accountId}/${envelopeId}/views/sender`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          returnUrl,
        },
      }
    );
  }

  addEnvelopeDocument(accountId, envelopeId, uuidDocument) {
    return fetch(
      `${this.getBaseUrl()}/envelopes/${accountId}/${envelopeId}/documents/${uuidDocument}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  importEnvelopeDocument(data) {
    const targetUrl = this.$.packageActiveOffer
      ? `/offers/${this.$.packageActiveOffer.uuid}`
      : '';

    return fetch(`${this.getBaseUrl()}${targetUrl}/documents/docusign`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: data,
    });
  }

  getReports() {
    return fetch(`${this.getBaseUrl()}/reports`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  allMessages() {
    return fetch(`${this.getBaseUrl()}/message`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  getMessageTemplates() {
    return fetch(`${this.getBaseUrl()}/message/templates`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  createMessage(message) {
    return fetch(`${this.getBaseUrl()}/message`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: message,
    });
  }

  allActivity() {
    return fetch(`${this.getBaseUrl()}/activity`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  exportActivityUrl() {
    return `${this.getBaseUrl()}/activity/export?token=${this.$.authToken}`;
  }

  exportMessages() {
    return `${this.getBaseUrl()}/message/export?token=${this.$.authToken}`;
  }

  importSellersShieldDocument(downloadSlug) {
    return fetch(
      `${this.getBaseUrl()}/sellersshield/documents/${downloadSlug}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  deleteSellersShield() {
    return fetch(`${this.getBaseUrl()}/sellersshield`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }
}
