import Store from './Store';

export default class extends Store {
  names = {
    accountAccessEditor: 'account-access-editor',
    accountSwitcher: 'account-switcher',
    adminBrokerageBillingConfirm: 'admin-brokerage-billing-confirm',
    adminBrokerageBillingEditor: 'admin-brokerage-billing-editor',
    adminBrokerageConfirm: 'admin-brokerage-confirm',
    adminBrokerageEditor: 'admin-brokerage-editor',
    adminUserBillingEditor: 'admin-user-billing-editor',
    adminUserTransferEditor: 'admin-user-transfer-editor',
    adminUserDisassociateEmailConfirm: 'admin-user-disassociate-email-confirm',
    brokerageAdminEditor: 'brokerage-admin-editor',
    brokerageAdminConfirm: 'brokerage-admin-confirm',
    brokerageBillingInvoiceEditor: 'brokerage-billing-invoice-editor',
    brokerageEditor: 'brokerage-editor',
    brokerageOfficeConfirm: 'brokerage-office-confirm',
    brokerageOfficeEditor: 'brokerage-office-editor',
    brokerageOfficeUserConfirm: 'brokerage-office-user-confirm',
    brokerageUserConfirm: 'brokerage-user-confirm',
    brokerageUserEditor: 'brokerage-user-editor',
    brokerageUserModal: 'brokerage-user-modal',
    changeUserEmailConfirm: 'change-user-email-confirm',
    contactConfirm: 'contact-confirm',
    contactEditor: 'contact-editor',
    creditCardConfirm: 'credit-card-confirm',
    creditCardEditor: 'credit-card-editor',
    grantedUserConfirm: 'granted-user-confirm',
    grantedUserEditor: 'granted-user-editor',
    grantedUserRemind: 'granted-user-remind',
    packageActivityPaywall: 'package-activity-paywall',
    packageAdminEditor: 'package-admin-editor',
    packageArchiveModal: 'package-archive-modal',
    packageConfirm: 'package-confirm',
    packageContributorActivity: 'package-contributor-activity',
    packageContributorConfirm: 'package-contributor-confirm',
    packageContributorEditor: 'package-contributor-editor',
    packageContributorEditorAgent1: 'package-contributor-editor-agent1',
    packageContributorEditorAgent2: 'package-contributor-editor-agent2',
    packageContributorInformationEditor:
      'package-contributor-information-editor',
    packageContributorsPending: 'package-contributors-pending',
    packageDocumentConfirm: 'package-document-confirm',
    packageDocumentDelete: 'package-documents-delete',
    packageDocumentEmailUpload: 'package-document-email-upload',
    packageDocumentExtract: 'package-document-extract',
    packageDocumentMove: 'package-document-move',
    packageDocumentRename: 'package-document-rename',
    packageDocumentSplit: 'package-document-split',
    packageDocumentUpload: 'package-document-upload',
    packageDocumentsCombine: 'package-documents-combine',
    packageDocumentsReorder: 'package-documents-reorder',
    packageDocumentsStamp: 'package-documents-stamp',
    packageDocusignImport: 'package-docusign-import',
    packageDocusignSending: 'package-docusign-sending',
    packageEditor: 'package-editor',
    packageEditorTabs: 'package-editor-tabs',
    packageLeave: 'package-leave',
    packageMLSLink: 'package-mls-link-modal',
    packageMLSUnlink: 'package-mls-unlink-modal',
    packageMessage: 'package-message',
    packageMessagingPaywall: 'package-messaging-paywall',
    packageNotifyViewers: 'package-notify-viewers',
    packageOfferDiscard: 'package-offer-discard',
    packageOfferDocumentDelete: 'package-offer-document-delete',
    packageOfferDocusignImport: 'package-offer-docusign-import',
    packageOfferRescind: 'package-offer-rescind',
    packageOfferRespond: 'package-offer-respond',
    packageOfferRestore: 'package-offer-restore',
    packageOfferSchedule: 'package-offer-schedule',
    packageOfferSubmit: 'package-offer-submit',
    packageOfferUnaccept: 'package-offer-unaccept',
    packageOfferUnacceptRescinded: 'package-offer-unaccept-rescinded',
    packageOfferWelcome: 'package-offer-welcome',
    packagePageReload: 'package-page-reload',
    packagePromotion: 'package-detail-promotion',
    packageMediaGallery: 'package-media-gallery',
    packagePropertyInfo: 'package-property-info',
    packageReportOrder: 'package-report-order',
    packageCashOffer: 'package-cash-offer',
    packageTradeIn: 'package-trade-in',
    packageSellersShield: 'package-sellers-shield',
    packageSearchDeleteConfirm: 'package-search-delete-confirm',
    packageSellersShieldConfirm: 'package-sellers-shield-confirm',
    packageSellersShieldImport: 'package-sellers-shield-import',
    packageSignupModal: 'package-sign-up-modal',
    packageUnarchiveModal: 'package-unarchive-modal',
    planManage: 'plan-manage',
    restorePreviousDocumentConfirm: 'restore-previous-document-confirm',
    switchToAnnualBillingConfirm: 'switch-to-annual-billing-confirm',
    templateNameConfirm: 'template-name-confirm',
    userTemplateConfirm: 'user-template-confirm',
    brokerageReport: 'brokerage-report',
  };

  pushModal(id) {
    const validIds = Object.keys(this.names).map((k) => this.names[k]);
    if (validIds.indexOf(id) < 0) {
      this.$.ToastList.addToast({
        message: `No such modal "${id}!`,
        type: 'error',
      });
      return;
    }
    this.$.modalListOpen.push(id);
  }

  isOpen(id) {
    return this.$.modalListOpen.indexOf(id) >= 0;
  }

  popModal() {
    this.$.modalListOpen.pop();
    this.$.packageDocumentsSelected = [];
  }

  clear() {
    this.$.modalListOpen = [];
    this.$.packageDocumentsSelected = [];
  }
}
