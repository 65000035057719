import config from '../config';
import Store from './Store';
import fetch from '../util/Fetch';

export default class extends Store {
  getBaseUrl() {
    return `${config.apiHost}/api/v1${
      this.$.currentUser.uuid ? '/users/' + this.$.currentUser.uuid : ''
    }/mls`;
  }

  getProperty(association, mlsId) {
    return fetch(`${this.getBaseUrl()}/${association}/properties/${mlsId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }
}
