import {
  clone as lodashClone,
  cloneDeep as lodashCloneDeep,
  every as lodashEvery,
} from 'lodash';
import { toJS } from 'mobx';

export function clone(obj) {
  return lodashClone(toJS(obj));
}

export function cloneDeep(obj) {
  return lodashCloneDeep(toJS(obj));
}

export function every(obj) {
  return lodashEvery(toJS(obj));
}
