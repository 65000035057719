import config from '../config';
import fetch from '../util/Fetch';
import { computed } from 'mobx';
import Store from './Store';

export default class extends Store {
  async searchPackages(searchTerm) {
    var results = [];

    if (searchTerm) {
      const rsp = await fetch(
        `${config.apiHost}/api/v1/admin/packages?q=${encodeURIComponent(
          searchTerm
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
        }
      );
      if (rsp.ok) {
        results = await rsp.json();
      }
    }

    this.$.adminPackageList = results;
    this.$.Pagination.update(
      this.$.adminPackageListPaginator,
      this.$.adminPackageList || []
    );
  }

  removePackage(packageUuid) {
    this.$.adminPackageList = this.$.adminPackageList.filter(
      (property) => property.propertyUuid !== packageUuid
    );
  }

  updateSlug(pkg) {
    this.$.adminPackageList.forEach((p) => {
      if (p.propertyUuid === pkg.uuid) {
        p.slug = pkg.slug;
      }
    });
  }

  @computed get filtered() {
    return this.$.adminPackageList.filter((p) => {
      const target =
        `${p.name}  ${p.email} ${p.property_address}`.toLowerCase();
      const query = this.$.adminPackageListSearch.toLowerCase();
      return query.length === 0 || target.indexOf(query) >= 0;
    });
  }

  @computed get paged() {
    return this.$.Pagination.getItems(
      this.$.adminPackageListPaginator,
      this.filtered
    );
  }

  static all(token) {
    return fetch(`${config.apiHost}/api/v1/admin/users/packages`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async searchDeletedPackages(searchTerm) {
    var results = [];

    if (searchTerm) {
      const rsp = await fetch(
        `${config.apiHost}/api/v1/admin/packages/deleted?q=${encodeURIComponent(
          searchTerm
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
        }
      );

      if (rsp.ok) {
        results = await rsp.json();
      }
    }

    this.$.adminPackageList = results;
    this.$.Pagination.update(
      this.$.adminPackageListPaginator,
      this.$.adminPackageList || []
    );
  }
}
