import { omit } from 'lodash';

function queryString(params) {
  return Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined)
    .map((key) => key + '=' + params[key])
    .join('&');
}

function userPrefix(params) {
  return `/app${params.userUuid ? `/users/${params.userUuid}` : ''}`;
}

function createBaseUrl(params) {
  return `${userPrefix(params)}/create${
    params.packageUuid ? `/${params.packageUuid}` : ''
  }`;
}

function packageBaseUrl(params) {
  return `${userPrefix(params)}/packages${
    params.packageUuid ? `/${params.packageUuid}` : ''
  }`;
}

export function navigateLogout(router) {
  return router.push('/auth/logout');
}

export function getPackageListUrl(params) {
  return `${packageBaseUrl({
    userUuid: params.userUuid,
  })}${params.page === 'buyers' ? '/buyers' : '/listings'}`;
}

export function navigateToPackageDetail(router, params) {
  const url = packageBaseUrl(params);
  router.push(url);
}

export function navigatePackageList(router, params) {
  const localParams = { ...params };
  if (!localParams.page) {
    localParams.page = 'listings';
  }
  const query = queryString(
    omit(localParams, ['packageUuid', 'documentUuid', 'tab'])
  );
  const url = getPackageListUrl(localParams);
  return router.push(`/app/packages?${query}`, url);
}

export function getCreateTabUrl(params) {
  return `${createBaseUrl(params)}?step=${params.step}`;
}

export function navigateCreateTab(router, params) {
  const query = queryString(params);
  const url = getCreateTabUrl(params);
  return router.push(`/app/create?${query}`, url, { shallow: true });
}

export function getPackageTabUrl(params) {
  return `${packageBaseUrl(params)}/${params.tab}`;
}

export function navigatePackageTab(router, params) {
  const query = queryString(omit(params, ['documentUuid', 'offerUuid']));
  const url = getPackageTabUrl(params);
  return router.push(`/app/packages/detail?${query}`, url, { shallow: true });
}

export function getPackageDocumentTabUrl(params) {
  return `${packageBaseUrl(params)}/${
    params.offerUuid ? `offers/${params.offerUuid}/` : ''
  }documents/${params.documentUuid}/${params.tab}`;
}

export function navigatePackageDocumentTab(router, params) {
  const query = queryString(params);
  const url = getPackageDocumentTabUrl(params);
  return router.push(`/app/packages/detail?${query}`, url, { shallow: true });
}

// Offer Tab
export function getPackageOfferTabUrl(params) {
  return `${packageBaseUrl(params)}/offers/${params.offerUuid}/${params.tab}${
    params.editTab ? '?editTab=' + params.editTab : ''
  }`;
}

export function navigatePackageOfferTab(router, params) {
  const args =
    params.tab !== 'edit' && params.tab !== 'create'
      ? omit(params, ['editTab'])
      : params;
  const query = queryString(args);
  const url = getPackageOfferTabUrl(args);
  return router.push(`/app/packages/detail?${query}`, url, { shallow: true });
}

// Offer Documents
export function getPackageOfferDocumentTabUrl(params) {
  return `${packageBaseUrl(params)}/offers/${params.offerUuid}/documents/${
    params.documentUuid
  }/${params.tab}`;
}

export function navigatePackageOfferDocumentTab(router, params) {
  const query = queryString(params);
  const url = getPackageOfferDocumentTabUrl(params);
  return router.push(`/app/packages/detail?${query}`, url, { shallow: true });
}

export function sanitizeRedirectUrl(link, defaultUrl = '/') {
  try {
    const url = new URL(link, window.location.origin);

    if (
      (url.protocol === 'http:' || url.protocol === 'https:') &&
      url.origin === window.location.origin &&
      url.pathname.startsWith('/app/')
    ) {
      return link;
    }

    return defaultUrl;
  } catch {
    return defaultUrl;
  }
}