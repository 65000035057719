import config from '../config';
import Store from './Store';
import fetch from '../util/Fetch';
import moment from 'moment';

export default class extends Store {
  upsert(envelope) {
    const index = this.$.packageEnvelopes.findIndex(
      (e) => e.uuid === envelope.uuid
    );
    if (index > -1) {
      this.$.packageEnvelopes[index] = envelope;
    } else {
      this.$.packageEnvelopes.push(envelope);
    }
  }

  remove(envelope) {
    this.$.packageEnvelopes = this.$.packageEnvelopes.filter(
      (e) => e.uuid !== envelope.uuid
    );
  }

  getBaseUrl = (accountId) => {
    return `${config.apiHost}/api/v1/docusign/restapi/v2/accounts/${accountId}`;
  };

  getEnvelopeDocuments = (accountId, envelopeId) => {
    return fetch(
      this.getBaseUrl(accountId) + '/envelopes/' + envelopeId + '/documents',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  };

  getDocusignUrl = (
    back = `${window.location.pathname}${window.location.search}`
  ) => {
    return `/auth/docusign?state=${btoa(
      JSON.stringify({
        preserveSession: true,
        back,
        flash: true,
      })
    )}`;
  };

  searchFolder = (accountId, status = 'completed', options = { url: '' }) => {
    options = {
      ...options,
      from_date: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      count: 5,
    };

    const query = [
      `from_date=${options.from_date}`,
      'order_by=completed',
      'order=desc',
      `count=${options.count}`,
    ].join('&');
    const url_suffix = options.url || `/search_folders/${status}?${query}`;
    const url = this.getBaseUrl(accountId) + url_suffix;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  };
}
