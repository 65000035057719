import Store from './Store';
import fetch from '../util/Fetch';
import { differenceBy, round, isNumber, get } from 'lodash';
import config from '../config';
import { computed } from 'mobx';
import accounting from 'accounting-js';

export default class extends Store {
  @computed
  get attention() {
    return this.$.packageOffers.filter((offer) => {
      return !offer.acknowledgedAt && !offer.rescindedAt;
    });
  }

  @computed
  get activeOfferAgents() {
    return this.$.Package.computedAgents.filter(
      (agent) =>
        !agent.deleted &&
        !agent.pending &&
        agent.hasOffer &&
        (this.$.packageActiveOffer.leadContributor.role === 'Admin'
          ? agent.email === this.$.packageActiveOffer.agentEmail
          : agent.cliqueUuid ===
            this.$.packageActiveOffer.leadContributor.cliqueUuid)
    );
  }

  @computed
  get otherOfferAgents() {
    return differenceBy(
      this.$.Package.computedAgents,
      this.activeOfferAgents,
      (agent) => agent.email
    ).filter((agent) => !agent.deleted && !agent.pending && agent.hasOffer);
  }

  findOffer(uuid) {
    return this.$.packageOffers.find((o) => o.uuid === uuid);
  }

  isLoanOffer(financeType) {
    if (!financeType) {
      return true;
    }
    return financeType === 'LOAN' || financeType === 'FHA/VA';
  }

  isCashOffer = (financeType) => {
    if (!financeType) {
      return false;
    }
    return financeType === 'CASH';
  };

  isStateLoan = (financeType) => {
    if (!financeType) {
      return false;
    }
    return financeType === 'FHA/VA';
  };

  hasFinanceContingency(financeType) {
    if (!financeType) {
      return true;
    }
    return ['LOAN', 'FHA/VA'].includes(financeType);
  }

  async wrapOffer(promise) {
    const rsp = await promise;
    const offer = await rsp.json();
    if (rsp.ok) {
      const index = this.$.packageOffers.findIndex(
        (o) => o.uuid === offer.uuid
      );
      if (index > -1) {
        this.$.packageOffers[index] = offer;
      }
      if (
        this.$.packageActiveOffer &&
        this.$.packageActiveOffer.uuid === offer.uuid
      ) {
        this.$.packageActiveOffer = offer;
      }
      return { error: false, message: '' };
    }

    return { error: true, message: offer.message };
  }

  async wrapOfferWithDocuments(promise) {
    const rsp = await promise;
    const json = await rsp.json();
    if (rsp.ok) {
      this.$.packageActiveOffer = json.offer;
      this.$.packageOfferDocuments = json.offerDocuments;

      return { error: false, message: '' };
    }

    return { error: true, message: json.message };
  }

  baseOfferUrl() {
    return `${config.apiHost}/api/v1${
      this.$.currentUser.uuid ? '/users/' + this.$.currentUser.uuid : ''
    }/packages/${this.$.packageDetail.uuid}/offers`;
  }

  getZipUrl() {
    return `${this.baseOfferUrl()}/${
      this.$.packageActiveOffer.uuid
    }/zip?token=${this.$.authToken}`;
  }

  downloadOfferSummary() {
    return `${this.baseOfferUrl()}/summaries?token=${this.$.authToken}`;
  }

  upsert(offer) {
    const index = this.$.packageOffers.findIndex((o) => o.uuid === offer.uuid);
    if (index > -1) {
      this.$.packageOffers[index] = offer;
    } else {
      this.$.packageOffers.push(offer);
    }
  }

  remove(offer) {
    this.$.packageOffers = this.$.packageOffers.filter(
      (o) => o.uuid !== offer.uuid
    );
  }

  getOffer(offerUuid) {
    return this.wrapOfferWithDocuments(
      fetch(`${this.$.Package.getBaseUrl()}/offers/${offerUuid}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  createOffer(offer) {
    return fetch(`${this.$.Package.getBaseUrl()}/offers`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: offer,
    });
  }

  updateOffer(offer) {
    return this.wrapOffer(
      fetch(`${this.$.Package.getBaseUrl()}/offers/${offer.uuid}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: offer,
      })
    );
  }

  acceptOffer(offerUuid, acceptMessage, declineMessage) {
    return fetch(`${this.$.Package.getBaseUrl()}/offers/${offerUuid}/accept`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: {
        acceptMessage,
        declineMessage,
      },
    });
  }

  declineOffer(offerUuid) {
    return fetch(`${this.$.Package.getBaseUrl()}/offers/${offerUuid}/decline`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  restoreOffer(offerUuid) {
    return this.wrapOffer(
      fetch(`${this.$.Package.getBaseUrl()}/offers/${offerUuid}/restore`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  removeOffer(offerUuid) {
    return fetch(`${this.$.Package.getBaseUrl()}/offers/${offerUuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  getOfferImageUrl(imageMeta, type) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/offers/${
        this.$.packageActiveOffer.uuid
      }/${type}/image/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: imageMeta,
      }
    );
  }

  updateOfferImage(imageMeta, type) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/offers/${
        this.$.packageActiveOffer.uuid
      }/${type}/image`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: imageMeta,
      }
    );
  }

  deleteOfferImage(type) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/offers/${
        this.$.packageActiveOffer.uuid
      }/${type}/image`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  importEnvelopeDocument(data) {
    return fetch(
      `${this.$.Package.getBaseUrl()}/offers/${
        this.$.packageActiveOffer.uuid
      }/documents/docusign`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: data,
      }
    );
  }

  notifyOfferViewers() {
    return fetch(
      `${this.$.Package.getBaseUrl()}/offers/${
        this.$.packageActiveOffer.uuid
      }/documents/upload`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  formatMoney(money) {
    return accounting.formatMoney(money, { symbol: '', precision: 0 });
  }

  formatSummary(summary) {
    let formattedBalanceOfDownPayment = '-';
    if (isNumber(summary.downPayment)) {
      /**
       * Calculated downpayment is the max of:
       * - Initial deposit
       * - Difference between purchase price and loan amount
       */
      const priceLoanDiff = summary.price - summary.loanAmount;
      if (summary.downPayment > priceLoanDiff) {
        formattedBalanceOfDownPayment = this.formatMoney(
          0 - summary.downPayment
        );
      } else {
        formattedBalanceOfDownPayment = this.formatMoney(
          summary.downPayment - get(summary, 'initialDeposit', 0)
        );
      }
    }
    return {
      ...summary,
      percentDown:
        isNumber(summary.price) && isNumber(summary.downPayment)
          ? round((summary.downPayment / summary.price) * 100, 1)
          : '-',
      formattedPrice: summary.price ? this.formatMoney(summary.price) : '',
      formattedInitialDeposit: summary.initialDeposit
        ? this.formatMoney(summary.initialDeposit)
        : '',
      formattedLoanAmount: summary.loanAmount
        ? this.formatMoney(summary.loanAmount)
        : '',
      formattedDownPayment: isNumber(summary.downPayment)
        ? this.formatMoney(summary.downPayment)
        : '-',
      formattedBalanceOfDownPayment,
      formattedFinanceContingencyWaived:
        summary.financeContingencyWaived === true ? 'Waived' : 'Days',
      formattedAppraisalContingencyWaived:
        summary.appraisalContingencyWaived === true ? 'Waived' : 'Days',
      formattedInspectionContingencyWaived:
        summary.inspectionContingencyWaived === true ? 'Waived' : 'Days',
      formattedHomeSaleContingencyWaived:
        summary.homeSaleContingencyWaived === true ||
        summary.homeSaleContingencyWaived === null
          ? 'Waived'
          : 'Yes',
    };
  }
}
