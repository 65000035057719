// stripes file extension
export function extensionLess(s) {
  if (s && s.toLowerCase().endsWith('.pdf')) {
    return s.substr(0, s.lastIndexOf('.'));
  }
  return s;
}

// formats file size in bytes to an appropriate label (mb/kb/bytes)
export function formatFileSize(bytes) {
  if (!bytes) {
    return '';
  }
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}
