import config from '../config';
import fetch from '../util/Fetch';
import Store from './Store';
import { sanitizeRedirectUrl } from '../util/Router';

export default class extends Store {
  impersonate({ userUuid, destinationUrl }) {
    return fetch(
      `/admin/impersonate/${userUuid}?return_uri=${window.location.pathname}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    ).then(() => {
      this.$.user.impersonating = true;
      window.location = destinationUrl;
    });
  }

  unimpersonate({ userUuid }) {
    return fetch(`/admin/impersonate/${userUuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    })
      .then((rsp) => {
        return rsp.json();
      })
      .then((body) => {
        window.location = sanitizeRedirectUrl(body.returnUri);
      });
  }

  updatePackage(pkg) {
    return fetch(`${config.apiHost}/api/v1/admin/packages/${pkg.uuid}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: pkg,
    }).then((rsp) => {
      if (!rsp.ok) {
        throw Error(rsp.statusText);
      }
      return rsp;
    });
  }

  // Parameters:
  //    packageUuid
  //    all: true or false, depending on whether you want to restore just admins(false) or everyone with access to the package(true)
  restorePackage(packageUuid, all) {
    return fetch(
      `${config.apiHost}/api/v1/admin/packages/${packageUuid}/restore`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: { all },
      }
    ).then((rsp) => {
      if (!rsp.ok) {
        throw new Error(rsp.statusText);
      }
      return rsp;
    });
  }
}
