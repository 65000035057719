import config from '../config';
import Store from './Store';
import RetryQueue from '../util/RetryQueue';
import fetch from '../util/Fetch';
import { includes } from 'lodash';
import { DOCUMENT_TYPES, MAX_FILE_UPLOAD_SIZE } from '../util/Constants';

export default class extends Store {
  // Helpers for switching between package and offer document targets
  // Offer docs and package docs
  allSelectedDocuments() {
    return [
      ...this.$.packageOfferDocumentsSelected,
      ...this.$.packageDocumentsSelected,
    ];
  }

  activeSelectedDocuments() {
    return this.$.packageActiveOffer
      ? this.$.packageOfferDocumentsSelected
      : this.$.packageDocumentsSelected;
  }

  activeDocuments() {
    return this.$.packageActiveOffer
      ? this.$.packageOfferDocuments
      : this.$.packageDocuments;
  }

  documentTargetUrl() {
    const offer = this.$.packageActiveOffer || this.$.previewOffer;
    return `${this.$.Package.getBaseUrl()}${
      offer ? `/offers/${offer.uuid}` : ''
    }`;
  }

  isCombinable() {
    let size = 0;
    this.activeDocuments().forEach((d) => {
      if (d.latest && d.latest.size) {
        size += d.latest.size;
      }
    });
    return size < MAX_FILE_UPLOAD_SIZE;
  }

  isSelected(uuid) {
    return (
      this.isOfferDocumentSelected(uuid) || this.isPackageDocumentSelected(uuid)
    );
  }

  allOfferDocumentsSelected() {
    return (
      this.$.packageOfferDocuments.length ===
      this.$.packageOfferDocumentsSelected.length
    );
  }

  allPackageDocumentsSelected(includeCoversheet = true) {
    if (includeCoversheet) {
      return (
        this.$.packageDocuments.length ===
        this.$.packageDocumentsSelected.length
      );
    }
    return (
      this.$.packageDocuments.filter(
        (d) => d.type !== DOCUMENT_TYPES.COVERSHEET
      ).length === this.$.packageDocumentsSelected.length
    );
  }

  isOfferDocumentSelected(uuid) {
    return !!this.$.packageOfferDocumentsSelected.find((d) => d.uuid === uuid);
  }

  isPackageDocumentSelected(uuid) {
    return !!this.$.packageDocumentsSelected.find((d) => d.uuid === uuid);
  }

  togglePackageDocument(uuid) {
    const selected = {
      [uuid]: true,
    };
    this.$.packageDocumentsSelected.forEach((d) =>
      selected[d.uuid] ? (selected[d.uuid] = false) : (selected[d.uuid] = true)
    );
    this.$.packageDocumentsSelected = this.$.packageDocuments.filter(
      (d) => selected[d.uuid]
    );
  }

  toggleOfferDocument(uuid) {
    const selected = {
      [uuid]: true,
    };
    this.$.packageOfferDocumentsSelected.forEach((d) =>
      selected[d.uuid] ? (selected[d.uuid] = false) : (selected[d.uuid] = true)
    );
    this.$.packageOfferDocumentsSelected = this.$.packageOfferDocuments.filter(
      (d) => selected[d.uuid]
    );
  }

  toggleAllOfferDocuments() {
    if (!this.allOfferDocumentsSelected()) {
      this.$.packageOfferDocumentsSelected = this.$.packageOfferDocuments;
    } else {
      this.$.packageOfferDocumentsSelected = [];
    }
  }

  toggleAllPackageDocuments(includeCoversheet = true) {
    if (!this.allPackageDocumentsSelected(includeCoversheet)) {
      if (includeCoversheet) {
        this.$.packageDocumentsSelected = this.$.packageDocuments;
      } else {
        this.$.packageDocumentsSelected = this.$.packageDocuments.filter(
          (d) => d.type !== DOCUMENT_TYPES.COVERSHEET
        );
      }
    } else {
      this.$.packageDocumentsSelected = [];
    }
  }

  canEdit() {
    if (this.$.packageActiveOffer) {
      return (
        includes(
          ['Admin', 'Buyer Agent'],
          this.$.packageActiveContributor.role
        ) && this.$.packageActiveOffer.status !== 'RESCINDED'
      );
    }
    return this.$.Package.canEdit();
  }

  clear() {
    this.$.packageDocumentsSelected = [];
    this.$.packageOfferDocumentsSelected = [];
  }

  upsert(doc) {
    if (this.$.packageActiveOffer) {
      const index = this.$.packageOfferDocuments.findIndex(
        (d) => d.uuid === doc.uuid
      );
      const hasPackageOfferDocuments = index > -1;
      if (hasPackageOfferDocuments) {
        this.dirty('document-updated', doc);
        this.$.packageOfferDocuments[index] = doc;
      } else {
        this.dirty('document-added', doc);
        this.$.packageOfferDocuments.push(doc);
      }
      // updating selected package offer documents if any
      const docSelectedIndex = this.$.packageOfferDocumentsSelected.findIndex(
        (d) => d.uuid === doc.uuid
      );
      const hasSelectedDoc = docSelectedIndex > -1;
      if (hasSelectedDoc) {
        this.$.packageOfferDocumentsSelected[docSelectedIndex] = doc;
      }
    } else {
      const index = this.$.packageDocuments.findIndex(
        (d) => d.uuid === doc.uuid
      );
      if (index > -1) {
        this.dirty('document-updated', doc);
        this.$.packageDocuments[index] = doc;
      } else {
        this.dirty('document-added', doc);
        this.$.packageDocuments.push(doc);
      }

      // updating selected package documents if any
      const docSelectedIndex = this.$.packageDocumentsSelected.findIndex(
        (d) => d.uuid === doc.uuid
      );
      const hasSelectedDoc = docSelectedIndex > -1;
      if (hasSelectedDoc) {
        this.$.packageDocumentsSelected[docSelectedIndex] = doc;
      }
    }
  }

  reorder() {
    if (this.$.packageActiveOffer) {
      this.$.packageActiveOffer.documentOrder =
        this.$.packageOfferDocuments.map((document) => document.uuid);
      return this.$.PackageOffer.updateOffer({
        uuid: this.$.packageActiveOffer.uuid,
        documentOrder: this.$.packageActiveOffer.documentOrder,
      });
    } else {
      this.dirty('coversheet-update', this.$.packageDocuments[0]);
      return this.$.Package.update({
        uuid: this.$.packageDetail.uuid,
        coversheet_document_order: this.$.packageDocuments.map(
          (document) => document.uuid
        ),
      });
    }
  }

  remove(doc) {
    this.dirty('document-removed', doc);
    this.$.packageOfferDocuments = this.$.packageOfferDocuments.filter(
      (d) => d.uuid !== doc.uuid
    );
    this.$.packageDocuments = this.$.packageDocuments.filter(
      (d) => d.uuid !== doc.uuid
    );
  }

  dirty(type, doc) {
    if (
      this.$.packageActiveOffer ||
      !this.$.packageContributors.some(
        (c) => !c.deleted && includes(['Buyer Agent', 'Buyer'], c.role)
      )
    ) {
      return;
    }
    this.$.packageCoversheetUpdates = this.$.packageCoversheetUpdates.filter(
      (d) => d.uuid !== doc.uuid
    );
    this.$.packageCoversheetUpdates.unshift({
      timestamp: new Date(),
      type,
      uuid: doc.uuid,
      name: doc.name,
    });
  }

  pristine() {
    this.$.packageCoversheetUpdates = [];
  }

  createCombined() {
    const documents = this.activeSelectedDocuments().length
      ? this.activeSelectedDocuments()
      : this.activeDocuments();
    const args = {
      name:
        this.$.packageDetail.property_address
          .replace(/[^0-9a-zA-Z\s]/g, '')
          .replace(/\s+/g, '-') + '.pdf',
      documents: documents.map((d) => {
        return { uuid: d.latest.uuid };
      }),
    };

    return RetryQueue(() => {
      return fetch(
        `${config.documentService}/packages/${this.$.packageDetail.uuid}/combine-async`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.$.authToken}`,
          },
          json: args,
        }
      );
    }).then((data) => {
      if (data.errorType) {
        return Promise.reject({
          errorType: data.errorType,
          errorMessage: data.errorMessage,
        });
      }
      return data;
    });
  }

  searchPackageDocuments(searchQuery) {
    return fetch(`${this.$.Package.getBaseUrl()}/search`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: {
        searchQuery,
      },
    });
  }

  savePackageDocument(doc, version) {
    return fetch(
      `${this.documentTargetUrl()}/documents${
        doc ? '/' + doc.uuid + '/versions' : ''
      }`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      }
    );
  }

  getPackageDocumentUrl(version) {
    return fetch(`${this.documentTargetUrl()}/documents/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: version,
    });
  }

  processPackageDocument(version) {
    return fetch(
      `${config.documentService}/packages/${this.$.packageDetail.uuid}/pipeline?documentUuid=${version.uuid}`,
      {
        method: 'POST',
      }
    );
  }

  trackPackageDocumentView(doc) {
    const documents = this.activeDocuments();
    const index = documents.findIndex((d) => d.uuid === doc.uuid);
    if (index > -1) {
      documents[index].isNewDocument = false;
      documents[index].isUpdatedDocument = false;
    }
    return fetch(`${this.documentTargetUrl()}/documents/${doc.uuid}/view`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  trackPackageDocumentDownload(doc) {
    return fetch(`${this.documentTargetUrl()}/documents/${doc.uuid}/download`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  updatePackageDocument(doc) {
    return fetch(`${this.documentTargetUrl()}/documents/${doc.uuid}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: doc,
    });
  }

  removePackageDocument(doc) {
    return fetch(`${this.documentTargetUrl()}/documents/${doc.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  sliceDocument(version, pages, name) {
    return fetch(
      `${config.documentService}/packages/${this.$.packageDetail.uuid}/slice?documentUuid=${version.uuid}`,
      {
        method: 'POST',
        json: {
          name,
          pages,
        },
      }
    );
  }

  combineDocuments(name, documents) {
    return fetch(
      `${config.documentService}/packages/${this.$.packageDetail.uuid}/merge`,
      {
        method: 'POST',
        json: {
          name,
          documents,
        },
      }
    );
  }

  annotateDocument(version, annotations) {
    return fetch(
      `${config.documentService}/packages/${this.$.packageDetail.uuid}/annotate?documentUuid=${version.uuid}`,
      {
        method: 'POST',
        json: {
          annotations,
        },
      }
    );
  }

  uploadPackageDocumentToS3(request, skipPipeline = false) {
    return fetch(
      `${config.uploadService}/upload/packages/${this.$.packageDetail.uuid}${
        skipPipeline ? '?skipPipeline=true' : ''
      }`,
      {
        method: 'POST',
        json: request,
      }
    );
  }

  // Used for uploading docusign documents
  importEnvelopeDocument = (packageUuid, offerUuid, data) => {
    const url = `${config.apiHost}/api/v1/packages/${packageUuid}/documents/docusign`;
    return fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: data,
    });
  };

  rotateDocument(version, pages) {
    return fetch(
      `${config.documentService}/packages/${this.$.packageDetail.uuid}/rotate?documentUuid=${version.uuid}`,
      {
        method: 'POST',
        json: {
          pages,
        },
      }
    );
  }

  rollBackVersion(uuidDocument, uuidVersion) {
    return fetch(
      `${this.documentTargetUrl()}/documents/${uuidDocument}/versions/${uuidVersion}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }
}
