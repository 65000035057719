import Observables from './Observables';

export default class Store {
  constructor() {
    this.$ = Observables.get();
  }

  isLoggedIn() {
    return !!this.$.authToken;
  }
}
