import fetch from '../util/Fetch';
import config from '../config';
import Store from './Store';

export default class extends Store {
  update(body) {
    if (!body) {
      return null;
    }

    // update the billing object
    this.$.billing = body.billing ? body.billing : body;

    // update billing inside parent object
    if (this.$.activeBrokerage) {
      this.$.activeBrokerage.billing = this.$.billing;
      this.$.user.brokerages.forEach((b, i) => {
        if (b.uuid === this.$.activeBrokerage.uuid) {
          this.$.user.brokerages[i].billing = this.$.billing;
        }
      });
    }

    this.$.currentUser.billing = this.$.billing;

    if (this.$.user.uuid === this.$.currentUser.uuid) {
      this.$.user.billing = this.$.billing;
    }

    return this.$.billing;
  }

  wrapper(promise) {
    return promise
      .then((rsp) => (rsp.ok ? rsp.json() : null))
      .then((json) => this.update(json));
  }

  basePath() {
    return this.$.activeBrokerage
      ? `${config.apiHost}/api/v1/brokerages/${this.$.activeBrokerage.uuid}`
      : `${config.apiHost}/api/v1/users/${this.$.currentUser.uuid}`;
  }

  saveCard(token) {
    return this.wrapper(
      fetch(`${this.basePath()}/billing/card`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: token,
      })
    );
  }

  removeCard() {
    return this.wrapper(
      fetch(`${this.basePath()}/billing/card`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      })
    );
  }

  purchasePlan(plan) {
    return this.wrapper(
      fetch(`${this.basePath()}/billing/plan`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: plan,
      })
    );
  }

  pausePlan(body) {
    return this.wrapper(
      fetch(`${this.basePath()}/billing/plan/pause`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: body,
      })
    );
  }

  cancelPlan(body) {
    return this.wrapper(
      fetch(`${this.basePath()}/billing/plan`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: body,
      })
    );
  }

  validateCoupon(couponCode) {
    return fetch(`${config.apiHost}/api/v1/billing/coupon/${couponCode}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  allUserCharges(couponCode) {
    return fetch(`${config.apiHost}/api/v1/billing/coupon/${couponCode}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }
}
