import fetch from 'isomorphic-unfetch';
import config from '../config';
import Router from 'next/router';
import { startsWith } from 'lodash';
import { toJS } from 'mobx';

export default function Fetch(url, args) {
  if (!args.headers) {
    args.headers = {};
  }
  if (args.headers) {
    if (startsWith(url, config.apiHost)) {
      args.headers['User-Agent'] = config.userAgent;
    }
  }

  if (args.json) {
    args.headers['Content-Type'] = 'application/json';
    args.body = JSON.stringify(toJS(args.json));
  }

  if (!args.hasOwnProperty('redirectUnauthenticated')) {
    args.redirectUnauthenticated = true;
  }

  return fetch(url, args)
    .then(async (rsp) => {
      if (rsp.status === 401 && args.redirectUnauthenticated) {
        await Router.push('/auth/logout');
      }
      return rsp;
    })
    .catch(() => {
      return {
        json: () => {
          return { message: 'Failed to make request' };
        },
        ok: false,
        status: 500,
      };
    });
}
