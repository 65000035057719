import { parse } from 'cookie';
import { observable, action, toJS } from 'mobx';
import Admin from './Admin';
import AdminBrokerageList from './AdminBrokerageList';
import AdminPackageList from './AdminPackageList';
import AdminUserList from './AdminUserList';
import Auth from './Auth';
import Billing from './Billing';
import Brokerage from './Brokerage';
import ContactList from './ContactList';
import GrantedList from './GrantedList';
import Link from './Link';
import Mls from './Mls';
import ModalList from './ModalList';
import OffCanvas from './OffCanvas';
import Package from './Package';
import PackageDocument from './PackageDocument';
import Docusign from './Docusign';
import PackageContributor from './PackageContributor';
import PackageOffer from './PackageOffer';
import Pagination from './Pagination';
import ToastList from './ToastList';
import User from './User';

let $ = null;

export default class Observables {
  // Auth
  @observable authToken = '';

  // Admin
  @observable adminPackageList = [];
  @observable adminPackageListSearch = '';
  adminPackageListPaginator = 'adminRecentPackages';

  // Admin Users
  @observable adminUserList = [];
  adminUserListPaginator = 'adminUsers';
  @observable adminUserListSelected = null;

  // Admin Brokerages
  @observable adminBrokerageList = [];
  @observable adminBrokerageListSelected = null;
  @observable adminBrokerageListSearch = '';
  adminBrokeragePaginator = 'adminBrokerages';

  // User
  @observable user = null;

  // Current User
  @observable currentUser = null;

  // Billing
  @observable billing = null;
  @observable chargeList = [];
  @observable invoiceEmail = null;

  // Access List
  accessListPaginator = 'accessList';

  // Granted List
  @observable grantedList = [];
  @observable grantedListSelected = [];
  @observable grantedListAction = '';

  // Session List
  @observable sessionList = [];

  // Selected Brokerage
  @observable selectedBrokerage = null;

  // Active Brokerage
  @observable activeBrokerage = null;

  // Brokerage Offices
  @observable brokerageOfficeList = [];
  @observable brokerageOfficeSelected = null;
  brokerageOfficeListPaginator = 'brokerageOffices';

  // Brokerage Admin
  brokerageAdminListPaginator = 'brokerageAdmins';

  // Brokerage Members
  @observable brokerageUserList = [];
  @observable brokerageUserSelected = null;
  brokerageUserListPaginator = 'brokerageUsers';

  // Package List
  packageListPaginator = 'packages';

  // Package Detail
  @observable packageDetail = null;
  @observable packageActiveContributor = null;
  @observable packageContributors = [];
  @observable packageContributorsSelected = [];
  @observable packageActiveDocument = null;
  @observable packageDocuments = [];
  @observable packageDocumentsSelected = [];
  @observable packageMediaGalleryStartingIndex = 0;
  @observable packageEditorTab = null;
  @observable packageCoversheetSaving = false;
  @observable packageCoversheetUpdates = [];
  @observable packageEnvelopes = [];
  @observable packageActiveOffer = null;
  @observable packageOfferDocumentsLoading = false;
  @observable packageOffers = [];
  @observable packageOfferDocuments = [];
  @observable packageOfferDocumentsSelected = [];
  @observable packageActivity = [];
  @observable packageSellersShield = null;
  @observable selectedRecipient = null;
  packageOfferListPaginator = 'packageOffers';

  // Package Document Preview
  @observable previewDocument = null;
  @observable previewOffer = null;
  @observable previewDocumentSearch = '';
  @observable previewDocumentPage = 1;

  // Contact List
  @observable contactList = [];
  @observable contactListSelected = [];
  @observable contactListAction = '';
  @observable contactListSearch = '';
  @observable contactListSort = 'name';
  contactListPaginator = 'contacts';

  // Pagination
  @observable paginationState = {};

  // Toast List
  @observable toastList = [];

  // Off Canvas
  @observable offCanvasOpen = false;

  // Modal List
  @observable modalListOpen = [];

  constructor(data = {}) {
    Object.keys(data).forEach((k) => (this[k] = data[k]));
  }

  @action.bound
  update(data = {}) {
    Object.keys(data).forEach((k) => (this[k] = data[k]));
  }

  static create(data = {}) {
    $ = new Observables(data);

    // instantiate helpers
    $.Admin = new Admin();
    $.AdminBrokerageList = new AdminBrokerageList();
    $.AdminPackageList = new AdminPackageList();
    $.AdminUserList = new AdminUserList();
    $.Auth = new Auth();
    $.Billing = new Billing();
    $.Brokerage = new Brokerage();
    $.ContactList = new ContactList();
    $.GrantedList = new GrantedList();
    $.Link = new Link();
    $.Mls = new Mls();
    $.ModalList = new ModalList();
    $.OffCanvas = new OffCanvas();
    $.Package = new Package();
    $.PackageDocument = new PackageDocument();
    $.PackageContributor = new PackageContributor();
    $.PackageOffer = new PackageOffer();
    $.Pagination = new Pagination();
    $.ToastList = new ToastList();
    $.User = new User();
    $.Docusign = new Docusign();
    return $;
  }

  static get() {
    if (!$) {
      throw new Error('Store has not be created!');
    }
    return $;
  }

  static destroy() {
    $ = null;
  }
}

export function defaultProps(cookie = '') {
  const isServer = typeof window === 'undefined';
  const cookies = parse(cookie);
  return {
    authToken: cookies.token || undefined,
    packageListFormat: cookies['package-list-format'] || 'grid',
    user: !isServer && $ ? toJS($.user) : undefined,
    currentUser: !isServer && $ ? toJS($.currentUser) : undefined,
    accessList: !isServer && $ ? toJS($.accessList) : undefined,
  };
}
