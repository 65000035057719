import config from '../config';

export const DOCUMENT_TYPES = {
  COVERSHEET: 'coversheet',
  DISCLOSURE: 'disclosure',
};

export const ENVIRONMENTS = {
  MOCK: 'mock',
  DEVELOPMENT: 'development',
  STAGE: 'stage',
  PRODUCTION: 'production',
};

export const PROPERTY_TYPES = {
  ALL: 'All',
  SINGLE_FAMILY: 'Single Family',
  MULTIFAMILY: 'Multi-Family',
  APARTMENT_CONDO_TIC: 'Apartment/Condo/TIC',
  COMMERCIAL: 'Commercial',
  LAND: 'Land',
};

export const MESSAGE_NOTIFICATION_TYPES = {
  INVITE: 'invite',
  PACKAGE_MESSAGE: 'package-message',
  RELAY: 'relay',
};

export const OFFER_FILTER_TYPES = {
  ALL: 'all',
  FAVORITES: 'favorites',
  UNVIEWED: 'unviewed',
  ACCEPTED: 'accepted',
  ARCHIVED: 'archived',
};

export const PACKAGE_FILTERS = {
  ALL: 'all',
  NEW: 'new',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const CONTRIBUTOR_FILTERS = {
  ACTIVE: 'Active',
  ADMIN: 'Admin',
  SELLER: 'Seller',
  BUYER_AGENT: 'Buyer Agent',
  BUYER: 'Buyer',
  REMOVED: 'Removed',
  ARCHIVED: 'Archived',
};

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_MED = 'MM/DD/YYYY, h:mm a';
export const DATE_FORMAT_LONG = 'MM/DD/YYYY, h:mm:ss a';
export const DATE_FORMAT_LONG_FULL = 'MMM Do YYYY, h:mm a';
export const DATE_FORMAT_MONTH = 'MMMM DD, YYYY';
export const DATE_FORMAT_MONTH_LONG = 'MMMM DD, YYYY h:mm a';

export const ACCESS_STATUSES = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  DECLINED: 'Declined',
};

export const CHARACTERISTICS = new Set([
  'bedrooms',
  'bathrooms',
  'squareFootage',
  'price',
  'yearBuilt',
  'description',
]);

export const TOAST_TIMEOUT = 2500;
export const MAX_FILE_UPLOAD_SIZE = 200 * 1024 * 1024;

export const BROADCAST_KEY = 'dIoSessionState';

export const LOT_SIZE_THRESHOLD = 10000;

export const LOT_SIZE_UNITS = {
  sqft: 'Sq. Ft',
  acres: 'Acres',
};
export const SQFT_PER_ACRE = 43560;

export const PROMOTION_TYPES = {
  DISCLOSURE_SOURCE: [
    {
      label: 'Premium Residential NHD Report',
      type: 'NATURAL_HAZARDS_PREMIUM',
      vendor: 'Disclosure Source',
      price: '$99',
      logo: `${config.cdnHost}${config.cdnPath}/disclosuresourcenhd.png`,
      url: 'https://www.disclosuresource.com',
      details: [
        {
          label: 'Natural Hazard Disclosures',
        },
        {
          label: 'Hazard Map',
        },
        {
          label: 'Tax Information',
        },
        {
          label: 'Environmental Disclosures',
        },
      ],
    },
  ],
  SELLERS_SHIELD: [
    {
      label: 'Seller Disclosure Forms',
      type: 'SELLER_DISCLOSURE_FORMS',
      vendor: "Seller's Sheild",
      price: 'Free',
      logo: `${config.cdnHost}${config.cdnPath}/sellersshield.png`,
      url: 'https://www.sellersshield.com',
      details: [
        {
          label: 'Transfer Disclosure Statement (TDS)',
        },
        {
          label: 'Seller Property Questionnaire (SPQ)',
        },
        {
          label: 'Exempt Seller Disclosure (ESD)',
        },
        {
          label: 'Lead Based Paint Disclosure (FLD)',
        },
        {
          label: 'Residential Earthquake Hazards Report',
        },
        {
          label: 'Optional Home Sale Legal Protection™ available',
        },
      ],
    },
  ],
};

export const PACKAGE_ATTACHMENT_REQUEST_TYPES = {
  HL_TITLE_AND_ESCROW_REQUEST: 'hl-escrow-approval-request',
  HL_TITLE_AND_ESCROW_DISMISSAL: 'hl-escrow-dismissal-request',
  HL_CASH_OFFER_REQUEST: 'hl-cash-offer-approval-request',
  HL_CASH_OFFER_DISMISSAL: 'hl-cash-offer-dismissal-request',
  HL_TRADE_IN_REQUEST: 'hl-trade-in-approval-request',
  HL_TRADE_IN_DISMISSAL: 'hl-trade-in-dismissal-request',
};

export const PACKAGE_OFFERS_VIEW_PREFERENCES = 'packageOffersViewPreferences';
