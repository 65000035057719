import fetch from '../util/Fetch';
import config from '../config';
import Store from './Store';

export default class extends Store {
  select(users, action = 'create') {
    this.$.grantedListSelected = users;
    this.$.grantedListAction = action;
  }

  deselect() {
    this.$.grantedListSelected = [];
    this.$.grantedListAction = '';
  }

  upsert(user) {
    const index = this.$.grantedList.findIndex((u) => u.uuid === user.uuid);
    if (index > -1) {
      this.$.grantedList[index] = user;
    } else {
      this.$.grantedList.push(user);
    }
  }

  remove(user) {
    this.$.grantedList = this.$.grantedList.filter((u) => u.uuid !== user.uuid);
  }

  createGrant(user) {
    return fetch(`${config.apiHost}/api/v1/me/users`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: user,
    });
  }

  remindGrant(user) {
    return fetch(`${config.apiHost}/api/v1/me/users/${user.uuid}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  removeGrant(user) {
    return fetch(`${config.apiHost}/api/v1/me/users/${user.uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  declineUserAccess(user) {
    return fetch(`${config.apiHost}/api/v1/me/users/${user.uuid}/decline`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }
}
