import Store from './Store';
import { TOAST_TIMEOUT } from '../util/Constants';

export default class extends Store {
  maxId = 0;

  makeId() {
    this.maxId += 1;
    return `toast-${this.maxId}`;
  }

  addToast(toast) {
    // bump id and set
    toast.id = this.makeId();

    // add toast to collection
    this.$.toastList.push(toast);

    // automatic removal
    if (toast.timeout) {
      toast.timeoutDuration = toast.timeoutDuration || TOAST_TIMEOUT;
      setTimeout(() => {
        this.removeToast(toast.id);
      }, toast.timeoutDuration);
    }

    // return toast for manual removal
    return toast.id;
  }

  removeToast(toastId) {
    this.$.toastList = this.$.toastList.filter((t) => t.id !== toastId);
  }
}
