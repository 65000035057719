import config from '../config';
import Store from './Store';
import fetch from '../util/Fetch';
import { computed } from 'mobx';
import { sortBy } from 'lodash';

export default class extends Store {
  @computed
  get sortedOffices() {
    return sortBy(this.$.brokerageOfficeList, (o) => o.name);
  }

  select(uuid) {
    this.$.selectedBrokerage = this.$.user.brokerages.find(
      (brokerage) => brokerage.uuid === uuid
    );
  }

  update(body) {
    if (!body) {
      return null;
    }

    this.$.activeBrokerage = body;

    this.$.user.brokerages = this.$.user.brokerages || [];
    this.$.user.brokerages.forEach((b, i) => {
      if (b.uuid === this.$.activeBrokerage.uuid) {
        this.$.user.brokerages[i] = this.$.activeBrokerage;
      }
    });

    return this.$.activeBrokerage;
  }

  wrapper(promise) {
    return promise
      .then((rsp) => (rsp.ok ? rsp.json() : null))
      .then((json) => this.update(json));
  }

  upsertOfficeMember(member) {
    const index = this.$.brokerageUserList.findIndex(
      (bu) => bu.uuid === member.uuid
    );
    if (index > -1) {
      member.offices = [
        member.offices[0],
        ...this.$.brokerageUserList[index].offices.filter(
          (o) => o.uuid !== member.offices[0].uuid
        ),
      ];
      this.$.brokerageUserList[index] = member;
    } else {
      this.$.brokerageUserList.push(member);
    }
    if (
      this.$.brokerageUserSelected &&
      this.$.brokerageUserSelected.uuid === member.uuid
    ) {
      this.$.brokerageUserSelected = member;
    }
  }

  removeOfficeMember(member, officeUuid) {
    const index = this.$.brokerageUserList.findIndex(
      (bu) => bu.uuid === member.uuid
    );
    if (index > -1) {
      this.$.brokerageUserList[index].offices = this.$.brokerageUserList[
        index
      ].offices.filter((o) => o.uuid !== officeUuid);

      if (
        this.$.brokerageUserSelected &&
        this.$.brokerageUserSelected.uuid === member.uuid
      ) {
        this.$.brokerageUserSelected = this.$.brokerageUserList[index];

        if (!this.$.brokerageUserSelected.offices.length) {
          this.$.brokerageUserList = this.$.brokerageUserList.filter(
            (u) => u.uuid !== this.$.brokerageUserSelected.uuid
          );
          this.$.brokerageUserSelected = null;
        }
      }
    }
  }

  create(brokerage) {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/brokerages`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: brokerage,
      })
    );
  }

  get(brokerageUuid) {
    return fetch(`${config.apiHost}/api/v1/brokerages/${brokerageUuid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  destroy(brokerageUuid) {
    return fetch(`${config.apiHost}/api/v1/brokerages/${brokerageUuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  //
  // Brokerage
  //
  saveBrokerage(brokerage) {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/brokerages/${brokerage.uuid}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: brokerage,
      })
    );
  }

  saveImg(brokerageUuid, version) {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/brokerages/${brokerageUuid}/image`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      })
    );
  }

  getImgUrl(brokerageUuid, version) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/image/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      }
    );
  }

  removeImg(brokerageUuid) {
    return this.wrapper(
      fetch(`${config.apiHost}/api/v1/brokerages/${brokerageUuid}/image`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          uuid: null,
        },
      })
    );
  }

  //
  // Admins
  //
  createAdmin(brokerageUuid, admin) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/admins`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: admin,
      }
    );
  }

  destroyAdmin(brokerageUuid, adminUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/admins/${adminUuid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  updateInvoiceUser(brokerageUuid, adminUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/billing/invoice/${adminUuid}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  //
  // Members
  //
  getMembers(brokerageUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/members`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  createMember(brokerageUuid, officeUuid, member) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/members`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: member,
      }
    );
  }

  updateMember(brokerageUuid, officeUuid, member) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/members/${member.uuid}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: member,
      }
    );
  }

  remindMembers(brokerageUuid, userUuids) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/members/activate`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          userUuids,
        },
      }
    );
  }

  removeMember(brokerageUuid, officeUuid, userUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/members/${userUuid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  //
  // Offices
  //
  getOffices(brokerageUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  createOffice(brokerageUuid, office) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: office,
      }
    );
  }

  updateOffice(brokerageUuid, office) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${office.uuid}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: office,
      }
    );
  }

  removeOffice(brokerageUuid, officeUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  saveOfficeImg(brokerageUuid, officeUuid, version) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/image`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      }
    );
  }

  getOfficeImgUrl(brokerageUuid, officeUuid, version) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/image/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: version,
      }
    );
  }

  removeOfficeImg(brokerageUuid, officeUuid) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/image`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: {
          uuid: null,
        },
      }
    );
  }

  //
  // Notifications
  //
  setNotificationPreference(brokerageUuid, notification) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/notifications`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: notification,
      }
    );
  }

  activateBrokerage(brokerageUuid, officeUuid, decline) {
    return fetch(
      `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/offices/${officeUuid}/me/activate`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: { decline },
      }
    );
  }

  downloadMemberReport({ brokerageUuid, start = '', end = '', officeUuids }) {
    return `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/reports/members?token=${this.$.authToken}&start=${start}&end=${end}&officeUuids=${officeUuids}`;
  }

  downloadListingsReport({ brokerageUuid, start = '', end = '', officeUuids }) {
    return `${config.apiHost}/api/v1/brokerages/${brokerageUuid}/reports/listings?token=${this.$.authToken}&start=${start}&end=${end}&officeUuids=${officeUuids}`;
  }
}
