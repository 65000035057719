import config from '../config';
import fetch from '../util/Fetch';
import { computed } from 'mobx';
import Store from './Store';

export default class extends Store {
  select(userUuid) {
    this.$.adminUserListSelected = this.$.adminUserList.find(
      (u) => u.uuid === userUuid
    );
  }

  async searchUsers(search) {
    const rsp = await fetch(
      `${config.apiHost}/api/v1/admin/users?q=${encodeURIComponent(search)}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
    const results = await rsp.json();
    this.$.adminUserList = results.users;
    this.$.Pagination.update(
      this.$.adminUserListPaginator,
      this.$.adminUserList || []
    );
  }

  updateUserBilling({ uuid, billing }) {
    return fetch(`${config.apiHost}/api/v1/admin/users/${uuid}/billing/plan`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: billing,
    });
  }

  updateUserSubscription({ uuid, subscriptionState }) {
    return fetch(
      `${config.apiHost}/api/v1/admin/users/${uuid}/billing/subscription/${subscriptionState}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  transferUserPackages({ uuid, email }) {
    return fetch(`${config.apiHost}/api/v1/admin/users/${uuid}/transfer`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: { email },
    });
  }

  disassociateOriginalEmail(uuid) {
    return fetch(
      `${config.apiHost}/api/v1/admin/users/${uuid}/remove_original_email`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  @computed get paged() {
    return this.$.Pagination.getItems(
      this.$.adminUserListPaginator,
      this.$.adminUserList
    );
  }
}
