import config from '../config';
import fetch from '../util/Fetch';
import Store from './Store';
import { computed } from 'mobx';
import { sortBy } from 'lodash';

export default class extends Store {
  setSearch(search) {
    this.$.adminBrokerageListSearch = search;
    this.$.Pagination.update(this.$.adminBrokeragePaginator, this.filtered);
  }

  @computed get filtered() {
    return sortBy(
      this.$.adminBrokerageList.filter((b) => {
        const target = `${b.name}`.toLowerCase();
        const query = this.$.adminBrokerageListSearch.toLowerCase();
        return query.length === 0 || target.indexOf(query) >= 0;
      }),
      (b) => b.name
    );
  }

  @computed get paged() {
    return this.$.Pagination.getItems(
      this.$.adminBrokeragePaginator,
      this.filtered
    );
  }

  upsert(brokerage) {
    const index = this.$.adminBrokerageList.findIndex(
      (b) => b.uuid === brokerage.uuid
    );
    if (index > -1) {
      this.$.adminBrokerageList[index] = brokerage;
    } else {
      this.$.adminBrokerageList.push(brokerage);
    }
    this.$.Pagination.update(this.$.adminBrokeragePaginator, this.filtered);
  }

  create({ name, homelightElite }) {
    return fetch(`${config.apiHost}/api/v1/admin/brokerages`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: {
        name,
        homelightElite,
      },
    });
  }

  destroy(uuid) {
    return fetch(`${config.apiHost}/api/v1/admin/brokerages/${uuid}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
    });
  }

  createAdmin({ uuid, name, email }) {
    return fetch(`${config.apiHost}/api/v1/admin/brokerages/${uuid}/admins`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.$.authToken}`,
      },
      json: {
        name,
        email,
      },
    });
  }

  sendAdminActivation({ uuid, userUuid }) {
    return fetch(
      `${config.apiHost}/api/v1/admin/brokerages/${uuid}/admins/${userUuid}/activate`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  cancelSubscription({ uuid }) {
    return fetch(
      `${config.apiHost}/api/v1/admin/brokerages/${uuid}/billing/plan`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
      }
    );
  }

  updateCard({ uuid, token }) {
    return fetch(
      `${config.apiHost}/api/v1/admin/brokerages/${uuid}/billing/card`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: token,
      }
    );
  }

  updateBilling({ uuid, billing }) {
    return fetch(
      `${config.apiHost}/api/v1/admin/brokerages/${uuid}/billing/plan`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.$.authToken}`,
        },
        json: billing,
      }
    );
  }

  static all(token) {
    return fetch(`${config.apiHost}/api/v1/admin/brokerages`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
